import React, { useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby-link";
import { motion } from "framer-motion";
import { ENTERING, EXITING } from "../constants/transition-states";

const menuLinks = [
  {
    name: "home",
    link: "/",
  },
  {
    name: "about",
    link: "/about",
  },
  {
    name: "services & pricing",
    link: "/services",
  },
  {
    name: "contact",
    link: "/contact",
  },
];

const MobileNav = ({ closeMenu }) => {
  const [animationState, setAnimationState] = useState(ENTERING);

  const navigateToPage = (link, closeMenu) => {
    setAnimationState(EXITING);
    navigate(link.link);
    setTimeout(() => {
      closeMenu();
    }, 500);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={animationState === EXITING ? { opacity: 0 } : { opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <div className="absolute top-0 left-0 h-screen w-screen bg-white flex flex-col justify-center items-center z-50">
        {menuLinks.map((link) => (
          <button
            className="my-10 text-xl"
            key={link.name}
            onClick={() => navigateToPage(link, closeMenu)}
          >
            {link.name}
          </button>
        ))}
      </div>
    </motion.div>
  );
};

MobileNav.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

export default MobileNav;
