import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import MobileNav from "../components/mobileNav";

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};

export const MenuContext = createContext([]);

export const useMenuContext = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = () => setShowMenu(true);
  const closeMenu = () => setShowMenu(false);

  return (
    <MenuContext.Provider value={[openMenu, closeMenu]}>
      <div
        className={showMenu ? "overflow-hidden max-h-screen relative -z-10" : "overflow-auto relative"}
      >
        {children}
      </div>
      {showMenu && <MobileNav closeMenu={closeMenu} />}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = propTypes;

export default MenuProvider;
