import "./src/styles/global.css";
import "./src/styles/typography.css";
import "@fontsource/kiwi-maru";

import React from "react";
import MenuProvider from "./src/providers/menuProvider";

export const wrapRootElement = ({ element }) => (
  <MenuProvider>{element}</MenuProvider>
);
